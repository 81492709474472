<template>
  <div class="authBox">
    <NavBar title="修改手机号"></NavBar>
    <div class="contentBox">
      <Space :height="30"></Space>
      <div class="tipsBox">
        <div class="active">验证原号码</div>
        <img :src="require(`@/assets/images/personal/admin/phone${step + 1}.png`)" alt="" />
        <div :class="{ active: step === 1 }">绑定新手机号</div>
      </div>
      <template v-if="step === 0">
        <van-field
          type="number"
          :label="$formatPhone(userInfo.phone)"
          :label-width="parseInt($pxToPxRatio(200), 10)"
          readonly
          :border="false"
          key="oldPhone"
        >
          <template #button>
            <SendCode url="/v1/ModifyUser/sendCodeByOldPhone" :phone="userInfo.phone"></SendCode>
          </template>
        </van-field>
        <van-divider />
        <van-field
          v-model="oldCode"
          label="验证码"
          type="number"
          :label-width="parseInt($pxToPxRatio(130), 10)"
          placeholder="请输入验证码"
          :border="false"
        ></van-field>
        <van-divider />
      </template>
      <template v-else>
        <van-field
          v-model="phone"
          type="number"
          placeholder="请输入新手机号"
          :label-width="parseInt($pxToPxRatio(200), 10)"
          :border="false"
          key="phone"
        >
          <template #label>
            <span class="mr20">手机号</span>
            <span @click="$refs.selectCountry.init()">+{{ countryCode }}</span>
          </template>
          <template #button>
            <SendCode url="/v1/ModifyUser/sendCodeByNewPhone" :phone="phone" :area-code="countryCode"></SendCode>
          </template>
        </van-field>
        <van-divider />
        <van-field
          v-model="code"
          label="验证码"
          type="number"
          :label-width="parseInt($pxToPxRatio(110), 10)"
          placeholder="请输入验证码"
          :border="false"
        ></van-field>
        <van-divider />
      </template>
    </div>
    <van-button class="fixedBottom" type="primary" block @click="onNext">{{
      step === 0 ? '下一步' : '确定'
    }}</van-button>
    <SelectCountry ref="selectCountry" :callback="countryCallback"></SelectCountry>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      step: 0,
      oldCode: '',
      phone: '',
      code: '',
      countryCode: 86,
    };
  },
  // created () {},
  // mounted () {},
  methods: {
    countryCallback(res) {
      this.countryCode = res.code;
    },
    async onNext() {
      if (this.step === 0) {
        if (!this.oldCode) {
          this.$toast('验证码不能为空');
          return;
        }
        let res = await this.$http('get', '/v1/ModifyUser/verifyByOldPhone', {
          code: this.oldCode,
        });
        if (res) {
          this.step = 1;
        }
        return;
      }
      if (this.step === 1) {
        if (!this.phone) {
          this.$toast('手机号不能为空');
          return;
        }
        if (this.countryCode === 86) {
          if (!/^1[3456789]\d{9}$/.test(this.phone)) {
            this.$toast('请输入正确手机号');
            return;
          }
        }
        if (!this.code) {
          this.$toast('验证码不能为空');
          return;
        }
        let res = await this.$http('post', '/v1/ModifyUser/modifyPhone', {
          areaCode: this.countryCode,
          phoneOld: this.userInfo.phone,
          codeOld: this.oldCode,
          phone: this.phone,
          code: this.code,
        });
        if (res) {
          let that = this;
          this.$logout();
          this.$toast('修改成功');
          that.$router.push('/account/login');
        }
      }
    },
  },
  // watch: {},
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    // phoneProcess() {
    //   if (this.countryCode === 86) {
    //     return this.phone;
    //   } else {
    //     return this.countryCode + this.phone;
    //   }
    // },
  },
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.tipsBox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90px;
  background: #f5f5fc;
  border-radius: 10px;
  color: @intro-color;
  img {
    width: 100px;
    height: 12px;
    margin: 0 20px;
  }
  > div.active {
    color: @title-color;
  }
}
</style>
